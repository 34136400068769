import * as React from "react";
import { Card, CardHeader, Grid, Link, Button } from "@material-ui/core";
import {
  SimpleForm,
  useMutation,
  useRedirect,
  useNotify,
  TextInput,
  useQuery,
  Loading,
  Error,
  ReferenceField,
  TextField,
  Toolbar,
  ShowButton,
  BooleanInput,
  SaveButton,
} from "react-admin";
import GenerateInput from "../page-fields/GenerateInput";
import { convertFileToBase64 } from "../addUploadFeature";
import { baseDomain } from "../App";
import ArrowBack from "@material-ui/icons/ArrowBack";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import QRCode from "react-qr-code";
import config from "../config";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useField, Field } from "react-final-form";
import DeleteButton from "../DeleteButton";
import ToolbarDeleteConfirm from "../ToolbarDeleteConfirm";

const validatePageEntity = (values) => {
  const errors = {};
  if (values.has_redirect && !values.redirect_slug) {
    errors.redirect_slug = [
      "The redirect slug is required if the redirect is on",
    ];
  }
  return errors;
};

const RedirectSlugInput = (props) => {
  const { input, meta } = useField(props.source);
  const has_redirect = input.value.has_redirect;

  if (has_redirect) {
    return (
      <TextInput
        source="redirect_slug"
        helperText="This is a unique identifier of the QR"
        label="Redirect to"
        {...props}
      />
    );
  }

  return <div></div>;
};

const PageEntityActions = ({ record, history }) => {
  const redirectTo = useRedirect();
  return (
    <Toolbar>
      <ShowButton
        icon={<ArrowBack />}
        label="Back"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          history.goBack();
        }}
        onAuxClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          history.goBack();
        }}
      />
      <Link href={`/${record.slug}`} underline="none" target="_blank">
        <Button
          aria-label="delete"
          color="primary"
          startIcon={<VisibilityIcon />}
          size="small"
        >
          View Page
        </Button>
      </Link>
      <ShowButton
        to={`/page-entity/${record.page_type}/${record.id}/statistics`}
        label="Statistics"
        icon={<EqualizerIcon />}
      />
    </Toolbar>
  );
};

export const PageEntityEdit = (props) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [mutate] = useMutation();

  const page_type = props.match.params.page_type;
  const id = props.match.params.id;

  const fields = useQuery({
    type: "getList",
    resource: "field",
    payload: {
      pagination: {},
      sort: {},
      filter: { page_type__id: page_type },
      order: { order: "ASC" },
    },
  });

  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "page-entity",
    payload: { id: id },
  });

  const editPageEntity = async (args) => {
    let fields_value = {};

    if (args["has_redirect"] || data.has_redirect) {
      fields_value = args["fields"];
    }

    for (const key of Object.keys(args)) {
      const newKey = key.substr(1);
      const reg = new RegExp("^\\d+$");
      if (!reg.test(newKey)) continue;

      if (args[key] && args[key].rawFile instanceof File) {
        const path = await convertFileToBase64(args[key]);
        args[key] = { value: path, fileName: args[key].File };
        fields_value[newKey] = args[key];
        continue;
      }
      if (key !== "slug" && key !== "enable") {
        fields_value[newKey] = { value: args[key] };
      }
    }

    return mutate(
      {
        type: "update",
        resource: "page-entity",
        payload: {
          id: id,
          data: {
            page_type: page_type,
            slug: args["slug"],
            enable: args["enable"],
            has_redirect: args["has_redirect"],
            redirect_slug: args["redirect_slug"],
            fields: { ...fields_value },
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          notify(`You just updated the page entity "${data.id}"`);
          redirectTo(`/page-entity/${page_type}`);
        },
        onFailure: (error) => {
          var allErrors = "";
          for (const property in error.body) {
            allErrors += `${property}:${error.body[property]},`;
          }
          notify(`Error: ${allErrors}`);
        },
      }
    );
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;

  const qrUrl = config.rootUrl() + data.slug;
  return (
    <Grid>
      <PageEntityActions {...props} record={data} />
      <Card>
        <CardHeader title={`Edit the Page with slug ${data.slug}`} />
        <div style={{ marginLeft: "20px" }}>
          <a href={qrUrl} target="_blank" rel="noopener noreferrer">
            <div>URL: {qrUrl}</div>
            <QRCode value={qrUrl} />
          </a>
        </div>

        <SimpleForm
          save={editPageEntity}
          record={data}
          resource="page-entity"
          basePath={`/page-entity/${page_type}`}
          validate={validatePageEntity}
          toolbar={<ToolbarDeleteConfirm/>}
        >
          <TextInput
            source="slug"
            initialValue={data.slug}
            helperText="This is a unique identifier of the QR"
            disabled={data.has_redirect}
          />
          {fields.data &&
            fields.data.map((field) => (
              <GenerateInput
                source={field}
                initialValue={
                  data.fields &&
                  data.fields[field.id] &&
                  data.fields[field.id].value
                }
                disabled={data.has_redirect}
              />
            ))}
          <BooleanInput
            source="enable"
            initialValue={data.enable}
            helperText="If this enables the QR is visible, if it isn't the QR page can't be accessed"
          />
          <BooleanInput
            source="has_redirect"
            initialValue={data.has_redirect}
            helperText="If this enables the QR will redirect the user to the slug below"
          />
          <RedirectSlugInput initialValue={data.redirect_slug} />
          <ReferenceField source="page_type" reference="page-types">
            <TextField source="name" />
          </ReferenceField>
        </SimpleForm>
      </Card>
    </Grid>
  );
};

export default PageEntityEdit;
