import * as React from "react";

function Logo(props) {
  return (
    <span style={{ backgroundColor: "#ffffff", borderRadius: " 15px" }}>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,700;1,300&display=swap');
      </style>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 177.27 53.579"
        height={52.579}
        width={150.27}
        backgroundColor="green"
        {...props}
      >
        <g transform="translate(-11.088 -84.517)">
          <text
            x="58.134422"
            y={127.20199}
            fill="#4d4d4d"
            fontFamily="Sans"
            fontSize="45.415px"
            letterSpacing="0px"
            strokeWidth="1.1354px"
            wordSpacing="0px"
            style={{ "line-height": "125%" }}
            xmlSpace="preserve"
          >
            <tspan
              x="58.134422"
              y="127.20199"
              fill="#4d4d4d"
              fontFamily="Montserrat"
              fontWeight="bold"
              letterSpacing="-1.3229px"
              strokeWidth="1.1354px"
            >
              {"MIRG"}
            </tspan>
          </text>
          <g transform="matrix(.41974 0 0 .41974 16.891 90.32)" fill="#008000">
            <path d="m10 10v23.333h23.333v-23.333zm16.667 16.667h-10v-10h10zm-16.667 39.999v23.334h23.333v-23.334zm16.667 16.668h-10v-10h10zm40-73.334v23.333h23.333v-23.333zm16.666 16.667h-10v-10h10zm0 13.333v20h-16.666v6.666h23.333v-26.666zm-16.666 33.334v16.666h6.666v-10h10v10h6.667v-16.666zm-26.667-63.334v6.667h13.333v16.666h6.667v-23.333z" />
            <path d="m53.333 40v13.334h-13.333v20h13.333v16.666h6.667v-23.334h-13.333v-6.666h13.333v-13.333h6.667v6.667h6.666v-13.334z" />
            <path d="m40 80h6.667v10h-6.667zm-16.667-26.666h10v6.666h-10z" />
            <path d="m40 23.333v16.667h-30v20h6.667v-13.333h30v-23.334z" />
          </g>
        </g>
      </svg>
    </span>
  );
}

export default Logo;
